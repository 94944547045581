:root {
  /******************** Meety ********************/
  --gray-gray-100: #f5f8fa;
  --gray-gray-400: #b5b5c3;
  --gray-gray-600: #7e8299;
  --gray-gray-700: #5e6278;
  --gray-gray-800: #3b3f55;
  --gray-gray-900: #181c32;
  --primary-blue-100: #def0fe;
  --primary-blue-200: #cde9ff;
  --primary-blue-300: #a9d8ff;
  --primary-blue-500: #008dff;
  --primary-blue-600: #266ef1;
  --purple-purple-100: #f2e3ff;
  --purple-purple-900: #3a1659;
  --red-red-100: #ffe1de;
  --red-red-200: #ffd2cd;
  --red-red-500: #f83446;
  --red-red-600: #de1135;
}

.sticky-preview {
  [class~='Polaris-Layout__Section--oneThird'] {
    position: sticky;
    top: var(--p-space-1200);
  }
}

.Polaris-Modal-Dialog {
  display: contents;
}
