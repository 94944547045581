.main {
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title {
    color: #008dff;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .text_center {
    text-align: center;
  }

  .text_left {
    color: #1f2021;
    text-align: left;
  }
}
